import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import ReactPlayer from 'react-player'; // You'll need to install react-player: npm install react-player
import './CryptoTrailer.css'; // Create a CSS file for styling
import {useNavigate} from 'react-router-dom'
function CryptoTrailer() {
  const [videoUrl, setVideoUrl] = useState(null);
  const navigate = useNavigate()
  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const storage = getStorage();
        const videoRef = ref(storage, '/Crypto2/Week1/1.mp4'); // Update video path
        const url = await getDownloadURL(videoRef);
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching video URL: ', error);
      }
    };
    fetchVideoUrl();
  }, []);

  return (
    <div className="trailer-container">
      <div className="trailer-content">
        <h1 className="trailer-title">Master Adobe Photoshop with Ease!</h1>
        <p className="trailer-description">
          Unleash your creativity and transform your ideas into stunning visuals with our comprehensive Adobe Photoshop course. Watch the trailer to explore the endless possibilities you can achieve!
        </p>
        {videoUrl ? (
          <ReactPlayer
            url={videoUrl}
            controls={true}
            playing={true}
            width="100%"
            height="500px"
            className="video-player"
          />
        ) : (
          <p>Loading video...</p>
        )}
        <button className="cta-button"
        onClick = {() => navigate('/Crypto')}
        >
          Start Creating Now!
        </button>
      </div>
    </div>
  );
}

export default CryptoTrailer;
