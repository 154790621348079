import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MainCourse.css'; // Custom CSS
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FaHeadset } from 'react-icons/fa'; // Importing icon for customer support
import NewMoneyTopBar from './NewMoneyTopBar';

function PurchasedCourses() {
  const [permission, setPermission] = useState(false);
  const tokenString = localStorage.getItem('clientToken');
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject?.token;
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('')
  const [contentType, setContentType] = useState('undefined')
  const [profilePictureSrc, setProfilePictureSrc] = useState('')
  const courses = [
    { id: 3, name: 'Cryptocurrency trading and investment academy', route: '/crypto', image: 'CoursesThumbnails/cryptoThumbnail.jpg', trailer: '/crypto-trailer' },
  ];

  // Check if user has completed courses
  const [coursesCompleted, setCoursesCompleted] = useState(false);

  // Fetch user's permission and course completion status
  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/verifyClient', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPermission(response.data.permission);
        setCoursesCompleted(response.data.coursesCompleted || false);
      } catch (error) {
        console.error('Error fetching permission:', error);
      }
    };
    fetchPermission();
  }, [token]);

  const filteredCourses = courses.filter(course =>
    course.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  useEffect(() => {
    fetch('https://sell-skill.com/api/endpoints/getClientProfileData')
      .then(response => response.json())
      .then(result => setName(result.name));
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getClientProfilePicture', {
          responseType: 'arraybuffer',
        });
        const base64 = Buffer.from(response.data, 'binary').toString('base64');
        const mimeType = response.headers['content-type'];
        setContentType(mimeType);
        setProfilePictureSrc(`data:${mimeType};base64,${base64}`);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchImage();
  }, [name]);
  return (
    <div className="container-fluid main-course" style={{ backgroundColor: '#001f3f', minHeight: '100vh', paddingTop: '50px' }}>
      {
        permission ? (
          <>
            {/* Top Bar */}


            {/* Search Input */}
            <div className="row justify-content-center mb-4">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search your courses..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            {/* Courses Section */}
            <div className="row justify-content-center">
              {filteredCourses.length > 0 ? (
                filteredCourses.map((course) => (
                  <div key={course.id} className="col-md-4 mb-4">
                    <div className="card course-card text-center">
                      <img src={course.image} className="card-img-top" alt={course.name} />
                      <div className="card-body">
                        <h5 className="card-title text-dark">{course.name}</h5>
                        <div className="btn-group mt-3" role="group">
                          <Link to={course.route} className="btn btn-success">Get In</Link>
                          <Link to={course.trailer} className="btn btn-outline-info">Watch Trailer</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-white">No courses found</p>
              )}
            </div>

            {/* Take Exam Button */}
            <div className="text-center mt-5">
              <Button
                variant="primary"
                size="lg"
                disabled={!coursesCompleted}
                className="exam-button"
              >
                Take the Exam and Enter Level One New Money World
              </Button>
            </div>
          </>
        ) : (
          <Container fluid className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <Card className="text-center p-4" style={{ width: '100%', maxWidth: '400px', backgroundColor: '#1e1e2f', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: '24px', marginBottom: '20px' }}>Welcome Back!</Card.Title>
                <Button variant="outline-light" onClick={() => navigate('/auth')} style={{ padding: '10px 20px', fontSize: '18px' }}>
                  Sign Up/In
                </Button>
              </Card.Body>
            </Card>
          </Container>
        )
      }
    </div>
  );
}

export default PurchasedCourses;

