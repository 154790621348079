import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MainCourse.css'; // Custom CSS
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Container, Card, ProgressBar } from 'react-bootstrap';
import NewMoneyTopBar from './NewMoneyTopBar';
import Confetti from 'react-confetti'; // Import Confetti

function MainCourse() {
  const [permission, setPermission] = useState(false);
  const [progress, setProgress] = useState(0); // Progress state for student improvement
  const [coursesCompleted, setCoursesCompleted] = useState(false); // Check if user has completed courses
  const tokenString = localStorage.getItem('clientToken');
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject?.token;
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [cryptoCompletion, setCryptoCompletion] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const courses = [
    { id: 3, name: 'Cryptocurrency trading and investment academy', route: '/crypto', image: 'CoursesThumbnails/cryptoThumbnail.jpg', trailer: '/crypto-trailer' },
  ];

  // Fetch permission

  useEffect (() => {
    const setCryptoCompleted = async() => {
      await axios.patch('https://sell-skill.com/api/endpoints/setCryptoCompleted')
    }
    setCryptoCompleted()
  }, [])

  
  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/verifyClient', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPermission(response.data.permission);
        setCoursesCompleted(response.data.coursesCompleted || false);
      } catch (error) {
        console.error('Error fetching permission:', error);
      }
    };
    fetchPermission();
  }, [token]);

  const filteredCourses = courses.filter(course =>
    course.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get crypto completion status
  useEffect(() => {
    const getCryptoCompletion = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getCryptoCompletion');
        setCryptoCompletion(response.data.completed); 
        if(response.data.completed){
          await axios.post('https://sell-skill.com/api/cryptoCertificate/upload')
        }
        console.log('cryptoCompletion ============================>  ', cryptoCompletion);
      } catch (error) {
        console.error("Error fetching completion status:", error);
      }
    };
    getCryptoCompletion();
  }, []);

  // Update window dimensions for Confetti
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  return (
    <div className="container-fluid main-course" style={{ backgroundColor: '#001f3f', minHeight: '100vh', paddingTop: '50px' }}>
      {cryptoCompletion && <Confetti width={windowWidth} height={windowHeight} />} {/* Fireworks/Confetti */}

      {
        permission ? (
          <>
            {/* Top Bar */}
            <NewMoneyTopBar />

            <h1 className="text-center text-white mb-5">YOUR ONLINE WEAPONS</h1>

            {/* Search Input */}
            <div className="row justify-content-center mb-4">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search weapons..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            {/* Courses Section */}
            <div className="row justify-content-center">
              {filteredCourses.length > 0 ? (
                filteredCourses.map((course) => (
                  <div key={course.id} className="col-md-4 mb-4">
                    <div className="card course-card text-center">
                      <img src={course.image} className="card-img-top" alt={course.name} />
                      <div className="card-body">
                        <h5 className="card-title text-dark">{course.name}</h5>
                        <div className="btn-group mt-3" role="group">
                          <Link to={course.route} className="btn btn-success">Get In</Link>
                          <Link to={course.trailer} className="btn btn-outline-info">Watch Trailer</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-white">No courses found</p>
              )}
            </div>

            {/* Progress Bar */}
            {/* <div className="text-center mt-5">
              <h5 className="text-white">Your Progress: {progress}%</h5>
              <ProgressBar
                now={progress}
                variant={progress === 100 ? 'success' : 'info'}
                label={`${progress}%`}
                className="mb-4"
                style={{ height: '30px', fontSize: '18px' }}
              />
            </div> */}

            {/* Take Exam Button */}
            <div className="text-center">
              <Button
                variant="primary"
                size="lg"
                disabled={!cryptoCompletion} // Disable if the crypto course is not completed
                className="exam-button"
              >
                Take the Exam and Enter Level One New Money World
              </Button>
            </div>
          </>
        ) : (
          <Container fluid className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <Card className="text-center p-4" style={{ width: '100%', maxWidth: '400px', backgroundColor: '#1e1e2f', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: '24px', marginBottom: '20px' }}>Welcome Back!</Card.Title>
                <Button variant="outline-light" onClick={() => navigate('/auth')} style={{ padding: '10px 20px', fontSize: '18px' }}>
                  Sign Up/In
                </Button>
              </Card.Body>
            </Card>
          </Container>
        )
      }
    </div>
  );
}

export default MainCourse;
